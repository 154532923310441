import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Upload from URL, Dropbox, Google Drive, and Other Sources',
  header: 'Upload from URL, Dropbox, Google Drive, and Other Sources',
  description: 'Uploadcare allows uploading files, images, and video from over a dozen sources like social media and cloud storage: Upload from URL, Dropbox, Google Drive, Facebook, Instagram, Box, Evernote, Flickr, OneDrive, and VK. Customizing OAuth is available!'
};
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CallToAction = makeShortcode("CallToAction");
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Uploadcare is a complete File API that provides serverless file handling for
your website or app. `}<a parentName="p" {...{
        "href": "/accounts/signup/"
      }}>{`Get Started`}</a>{`.`}</p>
    <p>{`Simply put, you can allow users to upload files from over a dozen sources right
on your webpage with no actual storage. From there, files go to our servers,
which you can use to store, process, and deliver content. We automatically cache
the files on our CDN, so you then address them via static CDN URLs. Those can be
stored in your database or even Moleskine.`}</p>
    <p>{`This article covers some common use cases related to receiving files that your
users upload from URL, device storage, cloud storage, and social media.`}</p>
    <p>{`Your users get authenticated to the third-party services using OAuth: we don’t
store any passwords or other credentials. You can customize the OAuth process:
add branded elements to the dialog where users grant their access permissions.`}</p>
    <p>{`The simplest way to receive files from your users is integrating our `}<a parentName="p" {...{
        "href": "/widget/configure/"
      }}>{`File
Uploader`}</a>{`. It’s a widget you add to your page with a few lines
of code:`}</p>
    <video playsInline={true} autoPlay={true} loop={true} muted={true} controls={true} poster="https://ucarecdn.com/3a2cf567-b07f-4ebc-afee-9662fac7e170/-/progressive/yes/-/format/auto/">
  <source src="https://ucarecdn.com/c2989b7e-8e67-4d08-93fa-65ade9862794/video.mp4" type="video/mp4" media="(min-device-pixel-ratio:2), (-webkit-min-device-pixel-ratio:2), (min--moz-device-pixel-ratio:2), (-o-min-device-pixel-ratio:2)" />
  <source src="https://ucarecdn.com/dfe8b44f-610c-4f0e-9e74-cf9571c6eea4/video.webm" type="video/webm" media="(min-device-pixel-ratio:2), (-webkit-min-device-pixel-ratio:2), (min--moz-device-pixel-ratio:2), (-o-min-device-pixel-ratio:2)" />
  <source src="https://ucarecdn.com/42a06e5c-f836-421a-ae97-46234fd5b201/video.mp4" type="video/mp4" media="(max-device-pixel-ratio:1), (-webkit-max-device-pixel-ratio:1), (max--moz-device-pixel-ratio:1), (-o-max-device-pixel-ratio:1)" />
  <source src="https://ucarecdn.com/006eff74-2edf-46e6-9ffa-2900a8710b7f/video.webm" type="video/webm" media="(max-device-pixel-ratio:1), (-webkit-max-device-pixel-ratio:1), (max--moz-device-pixel-ratio:1), (-o-max-device-pixel-ratio:1)" />
      <p>{`Your browser does not support the `}<code>{`video`}</code>{` element.`}</p>
    </video>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "lang-html"
      }}><code parentName="pre" {...{
          "className": "lang-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`//ucarecdn.com/libs/widget/3.x/uploadcare.full.js`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`//ucarecdn.com/libs/widget-tab-effects/1.x/uploadcare.tab-effects.js`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}><span parentName="span" {...{
              "className": "token language-javascript"
            }}>{`
  `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`UPLOADCARE_PUBLIC_KEY`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'your_public_key'`}</span>{`
  uploadcare`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`registerTab`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'preview'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` uploadcareEffectsTab`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`hidden`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`uploadcare-uploader`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`my_file`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`data-effects`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`crop,rotate,enhance,sharp,grayscale`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre></div>
    <p>{`Note, adding the script to your page will require updating
`}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`UPLOADCARE_PUBLIC_KEY`}</code>{` with your actual `}<a parentName="p" {...{
        "href": "/docs/account/#keys-public"
      }}>{`Public API Key`}</a>{`
for it to work properly. `}<a parentName="p" {...{
        "href": "/accounts/signup/"
      }}>{`Sign Up`}</a>{` for Uploadcare to get a free one.`}</p>
    <p>{`Our File Uploader uses tabbed navigation where every upload source is a separate
widget tab. We provide some pre-configured widget layouts below. Sure thing, you
can freely configure the set of those tabs.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#upload-from-local-storage"
        }}>{`Upload From Local Storage`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#upload-from-url"
        }}>{`Upload From URL`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#upload-from-dropbox"
        }}>{`Upload From Dropbox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#upload-from-google-drive"
        }}>{`Upload From Google Drive`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#upload-from-facebook"
        }}>{`Upload From Facebook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#upload-from-instagram"
        }}>{`Upload From Instagram`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#upload-from-box-evernote-flickr-onedrive-vk"
        }}>{`Upload From Box, Evernote, Flickr, OneDrive, and VK`}</a></li>
    </ul>
    <h2 {...{
      "id": "-upload-from-local-storage"
    }}><a parentName="h2" {...{
        "href": "#-upload-from-local-storage"
      }}><a id="upload-from-local-storage" href="#upload-from-local-storage"></a>{` Upload From Local Storage`}</a></h2>
    <p>{`In the simplest case, you can upload a file (or multiple files) from local
storage. To do that, you either tap the widget button or drag files from a
folder and drop them onto the widget.`}</p>
    <p>{`Here's a `}<a parentName="p" {...{
        "href": "/widget/configure/?multiple=true&tabs=file"
      }}>{`live widget`}</a>{` pre-configured for
multi-file uploads from local storage.`}</p>
    <p>{`Uploading files from device storage is a standard feature for web application
out there. Another commonly used practice is allowing users to fetch content
from URLs.`}</p>
    <h2 {...{
      "id": "-upload-from-url"
    }}><a parentName="h2" {...{
        "href": "#-upload-from-url"
      }}><a id="upload-from-url" href="#upload-from-url"></a>{` Upload From URL`}</a></h2>
    <p>{`Allowing users to upload files from URLs with the File Uploader is about adding
a dedicated tab to the widget’s UI. Here’s a `}<a parentName="p" {...{
        "href": "/widget/configure/?multiple=true&tabs=url"
      }}>{`live
preset`}</a>{` for the case.`}</p>
    <p>{`To fetch a file from URL, your users will go as copying and pasting the desired
(and publicly available) link into the widget dialog.`}</p>
    <p>{`Our servers will then upload a file to Uploadcare; it’s fast and saves user
bandwidth and traffic. Fetching files from cloud storage is similar from the
user experience perspective.`}</p>
    <h2 {...{
      "id": "-upload-from-dropbox"
    }}><a parentName="h2" {...{
        "href": "#-upload-from-dropbox"
      }}><a id="upload-from-dropbox" href="#upload-from-dropbox"></a>{` Upload From Dropbox`}</a></h2>
    <p><a parentName="p" {...{
        "href": "https://www.dropbox.com"
      }}>{`Dropbox`}</a>{` is a popular cloud storage option, and many folks keep their
images, documents, and other media there. So, it’s natural for users to pick
files from Dropbox and for your app to collect them.`}</p>
    <p>{`We've got a dedicated Dropbox tab in File Uploader and a ready-made `}<a parentName="p" {...{
        "href": "/widget/configure/?multiple=true&tabs=dropbox"
      }}>{`live
preset`}</a>{`. Grab your snippet from our
configurator or continue to the next section covering Google Drive.`}</p>
    <h2 {...{
      "id": "-upload-from-google-drive"
    }}><a parentName="h2" {...{
        "href": "#-upload-from-google-drive"
      }}><a id="upload-from-google-drive" href="#upload-from-google-drive"></a>{` Upload From Google Drive`}</a></h2>
    <p>{`As you’ve already guessed, this option fits those who store their stuff on
`}<a parentName="p" {...{
        "href": "https://drive.google.com"
      }}>{`Google Drive`}</a>{`. Another widget tab and another `}<a parentName="p" {...{
        "href": "/widget/configure/?multiple=true&tabs=gdrive"
      }}>{`live
preset`}</a>{`.`}</p>
    <p>{`The interesting part is (just as in the Dropbox case) that users only need to
authenticate once to make their files available on every future dialog launch.`}</p>
    <p>{`The Google Drive tab also allows filtering starred items to accelerate file
flows for your users.`}</p>
    <p>{`Now, let’s move on to Social Media, another place where users tend to keep tons
of their content.`}</p>
    <h2 {...{
      "id": "-upload-from-facebook"
    }}><a parentName="h2" {...{
        "href": "#-upload-from-facebook"
      }}><a id="upload-from-facebook" href="#upload-from-facebook"></a>{` Upload From Facebook`}</a></h2>
    <p>{`Your customers `}<em parentName="p">{`are`}</em>{` `}<a parentName="p" {...{
        "href": "https://facebook.com"
      }}>{`Facebook`}</a>{` users. The good news is they can
choose media directly from their account. We don’t store any user credentials;
accounts are connected via OAuth.`}</p>
    <p>{`Here’s a `}<a parentName="p" {...{
        "href": "/widget/configure/?multiple=true&tabs=facebook"
      }}>{`live preset`}</a>{` allowing to fetch media from
a Facebook account. Social Media are a great source of user-generated content or
UGC that’s widely used by brands in their marketing campaigns.`}</p>
    <p>{`This points us to the next platform that communicates visually.`}</p>
    <h2 {...{
      "id": "-upload-from-instagram"
    }}><a parentName="h2" {...{
        "href": "#-upload-from-instagram"
      }}><a id="upload-from-instagram" href="#upload-from-instagram"></a>{` Upload From Instagram`}</a></h2>
    <p><a parentName="p" {...{
        "href": "http://instagram.com/"
      }}>{`Instagram`}</a>{` is the one we’re talking about. It is a good source of
user-generated images for your web application. The simplest case would be users
uploading their avatars from one of the photos they spent hours editing 🙂`}</p>
    <p>{`The setup is similar: we’ve got an Instagram tab and a pre-configured
`}<a parentName="p" {...{
        "href": "/widget/configure/?multiple=true&tabs=instagram"
      }}>{`widget`}</a>{` for you.`}</p>
    <h2 {...{
      "id": "-upload-from-box-evernote-flickr-onedrive-and-vk"
    }}><a parentName="h2" {...{
        "href": "#-upload-from-box-evernote-flickr-onedrive-and-vk"
      }}><a id="upload-from-box-evernote-flickr-onedrive-vk" href="#upload-from-box-evernote-flickr-onedrive-vk"></a>{` Upload from Box, Evernote, Flickr, OneDrive, and VK`}</a></h2>
    <p>{`Now, here are a few other options we support as upload sources (and a `}<a parentName="p" {...{
        "href": "/widget/configure/?multiple=true&tabs=evernote+flickr+onedrive+box+vk"
      }}>{`live
preset`}</a>{` that combines all of them at once):`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.box.com/"
        }}>{`Box`}</a>{` is a popular enterprise cloud storage option.`}</li>
      <li parentName="ul">{`Those of your customers who love checklists will find `}<a parentName="li" {...{
          "href": "https://evernote.com/"
        }}>{`Evernote`}</a>{`
useful.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.flickr.com/"
        }}>{`Flickr`}</a>{`, a photo-sharing service.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://onedrive.live.com"
        }}>{`OneDrive`}</a>{`, another cloud storage options.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://vk.com/"
        }}>{`VK.com`}</a>{` aka “Vkontakte,” a Social Media option.`}</li>
    </ul>
    <CallToAction text="Start uploading for free" to="/accounts/signup/" mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      